.audio-container{
  position: sticky;

  bottom: rem(0);
  z-index: 1000;
  width: 100%;
  height: rem(0);
  overflow: hidden;
  background-image: $steel-gradient;
  filter: drop-shadow(0 rem(-2) rem(4) rgba(0 0 0 / 20%));
  transition: height 1s;

  &.opened{
    height: rem(90);
  }

  .audio-player{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: rem(90);

    .audio-head{
      height: rem(8);
      .stroke{
        flex-grow: 0;
        width: rem(28);
        height: rem(2);
        margin: rem(8) auto rem(0);
        background-color: $white;
        border-radius: rem(50);
      }
    }

    .audio-body{
      display: flex;
      gap: rem(16);
      justify-content: space-between;
      height: rem(70);

      margin-top: rem(16);
      padding: 0 rem(24);

      .audio-info-container{
        flex-grow: 1;
        overflow: hidden;

        mask-image: linear-gradient(to right, rgba(0 0 0 / 100%), rgba(0 0 0 / 100%), rgba(0 0 0 / 100%), rgba(0 0 0 / 0%));
        .audio-title{
          margin: 0;
          white-space: nowrap;
          transform: translateX(0%);

          &.animated{
            animation-name: title-scroll;
            animation-duration: 10s;
            animation-timing-function: linear;
            animation-delay: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: forwards;
          }


          @keyframes title-scroll {
            0% {
              transform: translateX(0%);
            }

            10%{
              transform: translateX(0%);
            }

            90%{
              transform: translateX(-100%);
            }

            100%{
              transform: translateX(-100%);
            }
        }
        }
      }

      .audio-controls-container{
        display: flex;
        flex-basis: auto;
        gap: rem(16);
        align-items: center;

        height: rem(40);

        .audio-back{
          width: rem(24);
          height: rem(24);
        }

        .audio-forward{
          width: rem(24);
          height: rem(24);
        }

        .audio-control{
          width: rem(36);
          height: rem(36);
          background-color: #3b4c5e;
          border-radius: rem(18);

          box-shadow: 0 rem(3) rem(7) 0 rgba(41  41  41 / 40%);
        }
      }
    }

    .audio-foot{
      height: rem(5);

      .audio-progress-container{
        width: 100%;
        height: rem(5);
        background-image: linear-gradient(to left, #a9b3bc, #67727d, #656b71);

        .audio-progress-fill{
          height: 100%;
          background-image: linear-gradient(to left, #f2b471, #b36c3c 0%);
        }
      }
    }
  }
}

.audio-clip-container{
  width: 100%;
  padding: 0 rem(24);
  cursor: pointer;

  .audio-clip{
    display: flex;
    gap: rem(16);
    padding: rem(16);
    background-color: $grey-1;

    .audio-clip-icon{
      button.icon-audio-play{
        width: rem(36);
        height: rem(36);
        margin-top: rem(3);
        background-color: $steel-3;
        border-radius: rem(18);
        box-shadow: 0 rem(3) rem(7) 0 rgba(41 41 41 / 40%);
      }
    }

    .audio-clip-info{
      display: flex;
      flex-direction: column;

      .audio-title{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0;
        overflow: hidden;
        color: $grey-4;
        font-family: $font-button;
        text-overflow: ellipsis;
      }

      .audio-time{
        margin: 0;
        color: $grey-4;
        font-size: rem(14);
      }
    }
  }
}