.artist-info{
  padding: rem(32) rem(24);
  color: $white;
  background-color: $grey-8;

  .tag{
    margin-bottom: rem(22);
  }

  h1, h2{
    color: $white;
  }

  h1{
    margin-bottom: rem(6);
  }

  h2.art-title{
    font-weight: 400;
    font-size: rem(22);
    font-family: HelveticaNeue;
    margin-bottom: rem(16);
  }
  
  .info-container{
    display: flex;
    flex-direction: column;
    gap: rem(8);
    padding: 0;

    .row{
      .list-title{
        height: 100%;
      }

      .list-detail{
        height: 100%;
        color: $grey-2;
      }

      &.map-button-row{
        margin: rem(20) 0 0;
      }

      &>a{
        padding: 0;
      }

      .map-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: rem(56);
        padding: 0 rem(20);
        color: $white;
        background-image: $steel-gradient;

        .map-label{
          position: relative;
          margin-top: rem(2);
          margin-left: rem(35);
          font-family: $font-button;

          &::before{
            position: absolute;

            top: rem(2);
            left: rem(-30);
            width: rem(21);
            height: rem(16);
            filter: invert(100%);
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21.3 16" style="enable-background:new 0 0 21.3 16;" xml:space="preserve"><path d="M14,16c0.1,0,0.1,0,0.2,0l6.6-2.3c0.1,0,0.3-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4V0.7c0-0.2-0.1-0.4-0.3-0.6C20.8,0,20.6,0,20.4,0L14,2.2L7.6,0C7.4,0,7.3,0,7.1,0L0.5,2.3c-0.1,0-0.3,0.1-0.3,0.2C0,2.7,0,2.8,0,3l0,12.3c0,0.2,0.1,0.4,0.3,0.6C0.5,16,0.7,16,0.9,16l6.4-2.2l6.4,2.2C13.8,16,13.9,16,14,16z M14.7,14.3V3.4l5.2-1.8v10.9L14.7,14.3z M13.3,14.3l-5.2-1.8V1.6l5.2,1.8V14.3z M1.4,14.3l0-10.9l5.2-1.8v10.9L1.4,14.3L1.4,14.3z"/></svg>');
          }
        }

        .map-arrow{
          width: rem(24);
          height: rem(24);
        }
      }
    }
  }
}