.menu-container{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 0;
  padding-top: rem(72);
  overflow: hidden;
  background-color: $grey-8;
  opacity: 0;
  transition: height 0.5s, opacity 0.5s;

  &.open{
    height: 100vh;
    opacity: 1;
  }

  .menu-items{
    display: flex;
    flex-direction: column;
    gap: rem(30);
    margin-top: rem(-72);
    padding: 0 rem(24);
    text-align: center;
    li{
      a.menu-item{
        color: $white;
      }
    }
  }

  .menu-foot{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(56);
    border-top: solid rem(1) $grey-2;

    .lang-button{
      width: rem(56);
      height: 100%;
      color: $white;
      font-family: $font-button;
      border-right: solid rem(1) $grey-2;

      &.selected{
        background-image: $bronze-gradient;
      }
    }

    .menu-foot-link{
      flex-grow: 1;
      color: $white;
      font-size: rem(14);
      text-align: center;
    }
  }
}