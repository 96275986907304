.image-cta{
  z-index: 10;
  padding: rem(32) rem(20) rem(40);
  background-color: $grey-8;
  
  border: rem(10) solid;
  border-width: rem(2);
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-image-source: linear-gradient(269deg, #c7dae7, #597792);
  border-image-slice: 1;
  
  .image-container{
    width: 100%;
    height: rem(336);
    margin-bottom: rem(18);
    overflow: hidden;
    border-radius: rem(5);

    img {
      object-fit: cover;
    }
  }

  h2, h4{
    color: $white;
  }

  .button-foot{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(26);

    .cta-link{
      position: relative;
      margin-right: rem(20);
      color: $white;

      &::after {
        position: absolute;
        width: rem(24);
        height: rem(24);
        filter: invert(100%);
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M9.5,19c-0.12,0-0.24-0.04-0.34-0.13c-0.2-0.19-0.22-0.5-0.03-0.71L14.82,12L9.13,5.84c-0.19-0.2-0.17-0.52,0.03-0.71c0.2-0.19,0.52-0.17,0.71,0.03l6,6.5c0.18,0.19,0.18,0.49,0,0.68l-6,6.5C9.77,18.95,9.63,19,9.5,19z"/></svg>');
      }

      &.tour-cta-link{
        &::after{
          position: absolute;
          width: rem(24);
          height: rem(24);
          filter: invert(100%);
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M11.5,15.5c-0.12,0-0.24-0.04-0.34-0.13l-6.5-6c-0.2-0.19-0.22-0.5-0.03-0.71c0.19-0.2,0.5-0.22,0.71-0.03l6.16,5.69l6.16-5.69c0.2-0.19,0.52-0.17,0.71,0.03c0.19,0.2,0.17,0.52-0.03,0.71l-6.5,6C11.74,15.46,11.62,15.5,11.5,15.5z"/></svg>');
        }

        &.opened {
          &::after{
            position: absolute;
            width: rem(24);
            height: rem(24);
            filter: invert(100%);
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M18,15.5c-0.12,0-0.24-0.04-0.34-0.13L11.5,9.68l-6.16,5.69c-0.2,0.19-0.52,0.18-0.71-0.03c-0.19-0.2-0.17-0.52,0.03-0.71l6.5-6c0.19-0.18,0.49-0.18,0.68,0l6.5,6c0.2,0.19,0.22,0.5,0.03,0.71C18.27,15.45,18.13,15.5,18,15.5z"/></svg>');
          }
        }
      }
    }
  }

  .tour-art-about-container{
    height: rem(0);
    overflow: hidden;

    &.opened{
      height: auto;
    }
    .tour-art-about {
      color: $white;
      background-color: $grey-7;
      border-radius: rem(5);
      margin-top: rem(35);

      h1{
        color: $white;
      }
  
      .tour-art-more-cta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        padding: rem(25) rem(24);
        border-top: $grey-4 solid rem(1);
  
        .chevron{
          width: rem(24);
          height: rem(24);
          filter: invert(100%);
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M9.5,19c-0.12,0-0.24-0.04-0.34-0.13c-0.2-0.19-0.22-0.5-0.03-0.71L14.82,12L9.13,5.84c-0.19-0.2-0.17-0.52,0.03-0.71c0.2-0.19,0.52-0.17,0.71,0.03l6,6.5c0.18,0.19,0.18,0.49,0,0.68l-6,6.5C9.77,18.95,9.63,19,9.5,19z"/></svg>');
        }
      }
    }
  }
}