/* stylelint-disable */

// Helvetica Neue
@font-face {
  font-weight: 500;
  font-family: HelveticaNeue;
  font-style: normal;
  src: url('../../fonts/NewTypography/HelveticaNowTextSAW05-Rg.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-family: HelveticaNeueLight;
  font-style: normal;
  src: url('../../fonts/NewTypography/HelveticaNowTextSAW05-Light.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-family: HelveticaNeueMedium;
  font-style: normal;
  src: url('../../fonts/NewTypography/HelveticaNowTextSAW05-Md.woff') format('opentype');
}

@font-face {
  font-weight: 800;
  font-family: HelveticaNeueBold;
  font-style: normal;
  src: url('../../fonts/NewTypography/HelveticaNowTextSAW05-Bold.woff') format('woff');
}

/*Body Font */
$font-body: HelveticaNeue;
$font-body-bold: HelveticaNeueBold;

/* Button */ 
$font-button: HelveticaNeueMedium;
