.collection-page{
  display: flex;
  flex-direction: column;

  &>.tag{
    margin: 0 rem(24) rem(0);
  }

  .hero-image{
    margin-top: rem(-72);
  }

  .content-section-container{
    &.sky-calgary {
      margin-bottom: rem(-30); /* Negative margin due to the positioning and size of the background gif */
      padding-bottom: rem(70);
      background-image: url('../../assets/images/home-logo/telus-sky/bottom_swoosh.gif');
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: rem(390) rem(200);
    }
  }

  .levels-container{
    padding: rem(32) rem(24) 0;
    color: $white;
    background-color: $grey-8;

    .level-title{
      margin-bottom: rem(30);
    }

    .level-desc{
      margin-bottom: rem(38);
    }

    .building-overview-cta{
      margin-bottom: rem(24);
    }
  }

  .other-container{
    padding: rem(24) rem(24) rem(40);
    background-color: $grey-8;

    .other-title{
      margin-bottom: rem(24);
    }

    .building-overview-cta{
      margin-bottom: rem(24);
    }
  }

  .bottom-line {
    width: 100%;
    height: rem(5);
    background: linear-gradient(180deg, #c7dae7 0%, #597792 100%);
  }
}