.header{
  position: sticky;
  top: rem(0);
  z-index: 1000;
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  height: rem(72);
  padding: rem(11) rem(15);
  background-color: rgba(0 0 0 / 0%);
  transition: background-color 0.5s;

  &.has-background{
    background-color: $black;
  }

  .header-lead{
    position: relative;
    flex-grow: 1;
    color: $white;
    
    .header-lead-item{
      position: absolute;
      top: 0;
      left: 0;
      animation-name: header-lead-item-in;
      animation-duration: 0.5s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;

      &.hidden{
        animation-name: header-lead-item-out;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }

      @keyframes header-lead-item-in {
        from {
          transform: translateX(rem(100));
          opacity: 0;
        }

        to{
          transform: translateX(0%);
          opacity: 1;
        }
      }

      @keyframes header-lead-item-out {
        from {
          transform: translateX(0%);
          opacity: 1;
        }

        to{
          transform: translateX(rem(-100));
          visibility: hidden;
          opacity: 0;
        }
      }

      &.back-button{
        position: relative;
        margin-left: rem(20);
        color: $white;
        &::before{
          position: absolute;
          top: rem(0);
          left: rem(-25);
          width: rem(24);
          height: rem(24);
          filter: invert(100%);
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M14.5,19c-0.13,0-0.27-0.05-0.37-0.16l-6-6.5c-0.18-0.19-0.18-0.49,0-0.68l6-6.5c0.19-0.2,0.5-0.22,0.71-0.03c0.2,0.19,0.22,0.5,0.03,0.71L9.18,12l5.69,6.16c0.19,0.2,0.17,0.52-0.03,0.71C14.74,18.96,14.62,19,14.5,19z"/></svg>');
        }
      }
    }

  }

  .menu-button{
    width: rem(24);
    height: rem(24);

    .svg-graphic{
      opacity: 0;
      transition: opacity 0.5s;
      fill: $white;

      &.visible{
        opacity: 1;
      }
    }
  }
}