.level-overview-page{
  .level-overview-container{
    .tag{
      margin: rem(36) rem(24) rem(0);
    }
  }

  .bottom-line {
    width: 100%;
    height: rem(5);
    background: linear-gradient(180deg, #c7dae7 0%, #597792 100%);
  }
}