.building-overview-cta{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: rem(200);
  padding: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: rem(10);

  .image-container{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img{
      object-fit: cover;
    }
  }

  .row{
    z-index: 20;
    margin: 0;
    padding: 0 rem(15);

    &.button-head{
      padding-top: rem(16);
    }

    &.button-body{
      height: rem(72);
      padding: rem(8) rem(15);
      text-align: left;

      h4{
        padding: 0;
        overflow: hidden;
        color: $white;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .location{
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0;

        p{
          margin: 0;
          overflow: hidden;
          color: $white;
          font-family: $font-button;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
  
        .arrow-icon{
          width: rem(24);
          height: rem(24);
        }
      }
    }
  }
}