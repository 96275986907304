.quote-component {  
  margin-bottom: rem(31);
  .quote {
    color: $steel-1;
  }
  .quotation-mark {
    font-style: normal;
  }
  p {
    margin-top: rem(3);
    color: $steel-1;
    font-weight: 500;
    font-size: rem(18);
    font-family: HelveticaNeue;
    text-align: right;    
    &.quote-name::before {
      margin-right: rem(5);      
      font-weight: bold;
      content: '–';
    }    
  }
}