.tag {
  width: fit-content;
  padding: rem(3) rem(20) rem(5);
  color: $white;
  border-radius: rem(4);
  &.steel-tag{
    background-image: $steel-gradient;
  }
  &.bronze-tag{
    background-image: $bronze-gradient;    
  }
}

