.btn {
  border: none;
  border-radius: rem(5);
  &:focus{
    outline: none;
  }
  &.btn-building-select {
    width: 100%;
    padding: rem(14) rem(23) rem(14);
    color: $white;
    font-weight: 500;
    font-size: rem(16);
    font-family: $font-button;
    background-color: $green;
  }
}