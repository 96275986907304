// stylelint-disable value-keyword-case
// Bootstrap
@import "bootstrap/dist/css/bootstrap.min.css";

@import 'global/variables';

// Functions
@import 'global/functions';

// Global
@import 'global/app';
@import 'global/fonts';
@import 'global/mixins';
@import 'global/svgs';
@import 'global/typography';

// Components
@import 'components/homeLogo';
@import 'components/header';
@import 'components/menu';
@import 'components/audio';
@import 'components/artistInfo';
@import 'components/gallery';
@import 'components/quote';
@import 'components/text';
@import 'components/heroImage';

@import 'components/common/imageCTA';
@import 'components/common/buildingOverviewCTA';
@import 'components/common/artNavButton';

// Pages
@import 'pages/mainBody';
@import 'pages/buildingSelect';
@import 'pages/collectionPage';
@import 'pages/tourOverviewPage';
@import 'pages/tourPage';
@import 'pages/levelOverviewPage';
@import 'pages/map';
@import 'pages/artLocation';
@import 'components/navigation';
@import 'components/tags';
@import 'components/buttons';


