.art-location-page{
  display: flex;
  flex-direction: column;

  &>.tag{
    margin: rem(36) rem(24) rem(0);
  }

  .nav-buttons{
    display: flex;
  }
}