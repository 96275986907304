.hero-image {
  height: rem(448);
  .image-container {
    z-index: 100;
    width: 100%;
    height: 100%;

    &.animate{
      position: fixed;
    }
    img { 
      object-fit: cover;
    }
  }
}