.gallery-component {
  .art-gallery {
    height: rem(448);
    .carousel-control-next, .carousel-control-prev {    
      position: absolute;
      display: inline-block;
      color: $black;
      background-color: transparent;
    }
  
    .carousel-control-next {
      right: 0;
      float: right;
    }
  
    .carousel-inner {
      display: flex;
      align-items: center;
      background-color: #121417;
  
      .carousel-item{
        padding: 0 rem(20);
        .image-container {
          display: block;
          width: 100%;
          height: rem(448);
        }
        img {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          object-fit: contain;
        }
      }
    }
  }
  
  .gallery-controls{
    display: flex;
    justify-content: space-between;
    padding: rem(16) rem(24);
    color: $white;
    background-color: $grey-8;
    .control {
      height: fit-content;
      padding: 0;
      color: $white;
      font-size: rem(14);
      background: none;
      border: none;
      &.btn-prev::before, &.btn-next::after {
        display: inline-block;
        width: rem(7);
        height: rem(13);
        vertical-align: rem(-1);
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        content: '';
      }
      &.btn-prev::before{
        margin-right: rem(10);
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='14' viewBox='0 0 7 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5.5.5 7l6 6.5' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
      }
      &.btn-next::after{
        margin-left: rem(10);
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9.5 5.5 6 6.5-6 6.5' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");            
      }
    }
    .slider-pagination{
      flex-grow: 1;
      text-align: center;
    }
  }
  
  .gallery-details, .gallery-caption{
    padding: rem(16) rem(24);
  }
  
  .gallery-details{
    width:100%;
    min-height: rem(70);
    background-color: $steel-4;
    .artist, .title, .date, .medium, .dimension, .credit {
      display:flex;
      margin-bottom: rem(8);
    }
    .list-title{
      min-width: rem(85);
      color: $white;
    }
    .list-detail {
      color: $grey-2;
    }
  }
  .bottom-line {
    width: 100%;
    height: rem(5);
    background: linear-gradient(180deg, #c7dae7 0%, #597792 100%);
  }
  
  .gallery-caption{
    width: 100%;
    height: rem(125);
    margin-top: 0;
    padding-top: rem(5);
    color: $white;
    font-size: rem(14);
    line-height: rem(22);
    background-color: $grey-8;
  }

  @keyframes fade-in-caption {
    from {
       opacity: 0;
    }
    to {
       opacity: 1;
    }
  }
 
 .fade-in {
   opacity: 0;
   animation: fade-in-caption ease-in-out 1;
   animation-duration: 1.3s;
   animation-fill-mode: forwards;
 }
}

// Gallery Preview
.gallery-preview{
  margin: rem(20) 0;
  padding: 0 rem(24);
  .image-container {
    display: block;
    width: rem(328);
    margin: 0 auto rem(20);
    img {
      object-fit: cover;
      box-shadow: 0 rem(7) rem(9) 0 rgb(0 0 0 / 25%);
    }    
  }
  .gallery-cta{
    display: flex;
    justify-content: space-between;
    padding: 0 rem(3) 0 rem(5);
    .images-counter{
      color: $grey-4;
      font-size: rem(14);
    }
    a {
      color: $grey-4;
      font-size: rem(14);
      text-decoration: none;
      &::after {
        display: inline-block;
        width: rem(12);
        height: rem(12);
        margin-left: rem(5);
        vertical-align: rem(-1);
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10.034 5.908 5.88 6.37-5.88 6.371' stroke='%23626262' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        content: '';
      }
    }
  }
}

// Tour Gallery Preview
.tour-gallery-preview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 rem(24) rem(24);
  padding: rem(14) rem(14);
  background-color: $grey-6;
  border-radius: rem(5);

  .gallery-preview-image-container{
    img{
      width: rem(110);
      height: rem(84);
      object-fit: cover;
    }
  }

  .gallery-cta{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(14) 0 rem(14) rem(20);
    font-size: rem(14);
    a{
      color: $white;

      .link-container{
        display: flex;
        flex-direction: row;

        .chevron{
          width: rem(24);
          height: rem(24);
          filter: invert(100%);
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M9.5,19c-0.12,0-0.24-0.04-0.34-0.13c-0.2-0.19-0.22-0.5-0.03-0.71L14.82,12L9.13,5.84c-0.19-0.2-0.17-0.52,0.03-0.71c0.2-0.19,0.52-0.17,0.71,0.03l6,6.5c0.18,0.19,0.18,0.49,0,0.68l-6,6.5C9.77,18.95,9.63,19,9.5,19z"/></svg>');
        }
      }

    }

    .images-counter{
      margin: 0;
    }
  }
}