.menu{
  display: flex;
  flex-direction: column;
  height: 100vh;
  .primary-menu{
    display: flex;
    flex-direction: column;
    gap: rem(40);
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $white;
    text-align: center;
    background-color: $grey-8;
    ul > li {
      margin-bottom: rem(40);
      font-size: rem(22);
    }
  } 
  .secondary-menu, .language-selector, .info-telus {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: rem(56);
    color: $white;
    background-color: $grey-8;
  }
  
  .secondary-menu {
    justify-content: space-between;
    margin-top: auto;
    border-top: rem(1) solid $white;
    .language-selector {
      width: rem(112);
      > * {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(56);
        height: rem(56);
        border-right: rem(1) solid $white;
        &.selected {
          background-image: $bronze-gradient;
        }
      }
    }
    .info-telus{
      gap: rem(25);
      justify-content: center;
      margin: 0 auto;
      font-size: rem(14);
    }
  }
}