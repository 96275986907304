.art-nav-button{
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 50vw;
  height: rem(56);

  padding: 0 rem(12);
  color: $white;
  background: $steel-gradient;

  &.forward{
    flex-direction: row-reverse;
  }

  &.backward{
    flex-direction: row;
  }

  p{
    margin: 0;
    padding: 0;
  }

  .arrow{
    width: rem(24);
    height: rem(24);
  }
}