/* stylelint-disable */

@use "sass:math";

// Core variables
$version: '0.5.9';

// Base font size
$html-base-font-size: 16px;

$html-font-size: $html-base-font-size;

// Core features
$rtl: false;

// Core colours
$white: #ffffff;
$white-hover: #dadadc;
$black: #000000;
$black-hover: #232323;

$purple: #4b286d;
$bronze: #c58b4d;
$steel-1: #6c7e8e;
$steel-2: #51667a;
$steel-3: #3b4c5e;
$steel-4: #202630;
$grey-1: #f7f7f8;
$grey-2: #d8d8d8;
$grey-3: #71757b;
$grey-4: #626262;
$grey-5: #525252;
$grey-6: #4d4d4d;
$grey-7: #2a2c2e;
$green: #2b8000;
$grey-8: #1a1d21;
$grey-9: #121417;

// Gradients
$bronze-gradient: linear-gradient(to right, #a85b27, $bronze);
$steel-gradient: linear-gradient(to right, #364759, #667d92);

// Unit sizes
$unit-o: 0.05rem;
$unit-h: 0.1rem;
$unit-1: 0.2rem;
$unit-2: 0.4rem;
$unit-3: 0.6rem;
$unit-4: 0.8rem;
$unit-5: 1rem;
$unit-6: 1.2rem;
$unit-7: 1.4rem;
$unit-8: 1.6rem;
$unit-9: 1.8rem;
$unit-10: 2rem;
$unit-12: 2.4rem;
$unit-16: 3.2rem;

$line-height: 1.2rem;

// Sizes
$layout-spacing: $unit-2;
$layout-spacing-sm: $unit-1;
$layout-spacing-lg: $unit-4;
$border-radius: $unit-h;
$border-width: $unit-o;
$border-width-lg: $unit-h;
$control-size: $unit-9;
$control-size-sm: $unit-7;
$control-size-lg: $unit-10;
$control-padding-x: $unit-2;
$control-padding-x-sm: $unit-2 * 0.75;
$control-padding-x-lg: $unit-2 * 1.5;
$control-padding-y: math.div($control-size - $line-height, 2) - $border-width;
$control-padding-y-sm: math.div($control-size-sm - $line-height, 2) - $border-width;
$control-icon-size: 0.8rem;