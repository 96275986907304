.building-select-container{
  display: flex;
  flex-direction: column;

  height: 100vh;

  padding: rem(64) rem(24) 0;
  background-image: url('/assets/images/flower-purple.jpg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;

  .building-select-icon-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(40);
    
    figure{
      margin: 0;
      img{
        &.telus-logo{
          width: rem(128);
        }
      }
    }

    .language-toggle{
      color: $green;
      text-decoration: underline;
    }
  }

  h6{
    margin-bottom: rem(6);
  }

  h3{
    margin-bottom: rem(15);
    color: $purple;
  }

  p{
    margin-bottom: rem(20);
  }

  label{
    margin-bottom: rem(5);
    color: $grey-7;
    font-weight: 500;
    font-size: rem(14);
    font-family: $font-button;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
  }

  .building-select-input{
    display: block;
    width: 100%;
    height: rem(40);
    margin-bottom: rem(32);
    padding: rem(8) rem(10);
    background-color: $white;
    background-repeat: no-repeat;
    background-position: right rem(5) center;
    background-size: 1.5em;
    border: rem(1) solid $grey-3;
    border-radius: rem(2);
    appearance: none;
  }
}