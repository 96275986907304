.icon-audio-play{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}</style><path class="st0" d="M8,19.5c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.3-0.3-0.3-0.4V5c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.4-0.1,0.5,0l11,7c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4l-11,7C8.2,19.5,8.1,19.5,8,19.5z M8.5,5.9v12.2l9.6-6.1L8.5,5.9z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;
  background-size: rem(27) rem(27);
}

.icon-audio-pause{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}</style><path class="st0" d="M18,4.5h-4c-0.3,0-0.5,0.2-0.5,0.5v14c0,0.3,0.2,0.5,0.5,0.5h4c0.3,0,0.5-0.2,0.5-0.5V5C18.5,4.7,18.3,4.5,18,4.5z M17.5,18.5h-3v-13h3V18.5z M10,4.5H6C5.7,4.5,5.5,4.7,5.5,5v14c0,0.3,0.2,0.5,0.5,0.5h4c0.3,0,0.5-0.2,0.5-0.5V5C10.5,4.7,10.3,4.5,10,4.5z M9.5,18.5h-3v-13h3V18.5z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;
  background-size: rem(27) rem(27);
}

.icon-audio-forward{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M20,12.5c-0.28,0-0.5,0.22-0.5,0.5c0,4.14-3.36,7.5-7.5,7.5S4.5,17.14,4.5,13S7.86,5.5,12,5.5h0.4l-2.27,2.46c-0.19,0.2-0.17,0.52,0.03,0.71c0.1,0.09,0.22,0.13,0.34,0.13c0.13,0,0.27-0.05,0.37-0.16l3-3.25c0.18-0.19,0.18-0.49,0-0.68l-3-3.25c-0.19-0.2-0.5-0.22-0.71-0.03c-0.2,0.19-0.22,0.5-0.03,0.71l2.18,2.36H12c-4.69,0-8.5,3.81-8.5,8.5c0,4.69,3.81,8.5,8.5,8.5c4.69,0,8.5-3.81,8.5-8.5C20.5,12.72,20.28,12.5,20,12.5z M12.81,14.99c-0.08,0.11-0.19,0.2-0.32,0.26c-0.13,0.06-0.3,0.1-0.49,0.1c-0.3,0-0.54-0.08-0.72-0.24c-0.18-0.16-0.29-0.4-0.31-0.7h-0.91c0.01,0.28,0.07,0.53,0.17,0.74c0.1,0.21,0.24,0.39,0.42,0.53c0.18,0.14,0.38,0.25,0.61,0.32c0.23,0.07,0.48,0.11,0.76,0.11c0.34,0,0.63-0.06,0.88-0.19s0.45-0.29,0.6-0.48c0.16-0.19,0.27-0.41,0.34-0.65s0.11-0.47,0.11-0.69c0-0.27-0.04-0.51-0.12-0.74c-0.08-0.23-0.19-0.43-0.34-0.6c-0.15-0.17-0.33-0.3-0.55-0.39c-0.22-0.09-0.47-0.14-0.74-0.14c-0.19,0-0.37,0.04-0.56,0.11c-0.19,0.07-0.35,0.18-0.47,0.32l-0.02-0.02l0.29-1.42h2.22V10.4h-2.9l-0.6,3.06h0.91c0.07-0.2,0.2-0.33,0.36-0.4c0.17-0.07,0.35-0.11,0.54-0.11c0.18,0,0.34,0.03,0.48,0.1c0.14,0.06,0.25,0.15,0.33,0.26c0.09,0.11,0.15,0.23,0.19,0.37c0.04,0.14,0.06,0.29,0.06,0.44c0,0.17-0.02,0.33-0.05,0.48C12.95,14.75,12.89,14.88,12.81,14.99z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-audio-back{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 25" style="enable-background:new 0 0 24 25;" xml:space="preserve"><path d="M12,4.55h-0.31l2.18-2.36c0.19-0.2,0.17-0.52-0.03-0.71c-0.2-0.19-0.52-0.17-0.71,0.03l-3,3.25c-0.18,0.19-0.18,0.49,0,0.68l3,3.25c0.1,0.11,0.23,0.16,0.37,0.16c0.12,0,0.24-0.04,0.34-0.13c0.2-0.19,0.22-0.5,0.03-0.71L11.6,5.55H12c4.14,0,7.5,3.36,7.5,7.5s-3.36,7.5-7.5,7.5s-7.5-3.36-7.5-7.5c0-0.28-0.22-0.5-0.5-0.5s-0.5,0.22-0.5,0.5c0,4.69,3.81,8.5,8.5,8.5c4.69,0,8.5-3.81,8.5-8.5C20.5,8.36,16.69,4.55,12,4.55z M12.81,14.99c-0.08,0.11-0.19,0.2-0.32,0.26c-0.13,0.06-0.3,0.1-0.49,0.1c-0.3,0-0.54-0.08-0.72-0.24c-0.18-0.16-0.29-0.4-0.31-0.7h-0.91c0.01,0.28,0.07,0.53,0.17,0.74c0.1,0.21,0.24,0.39,0.42,0.53c0.18,0.14,0.38,0.25,0.61,0.32c0.23,0.07,0.48,0.11,0.76,0.11c0.34,0,0.63-0.06,0.88-0.19s0.45-0.29,0.6-0.48c0.16-0.19,0.27-0.41,0.34-0.65s0.11-0.47,0.11-0.69c0-0.27-0.04-0.51-0.12-0.74c-0.08-0.23-0.19-0.43-0.34-0.6c-0.15-0.17-0.33-0.3-0.55-0.39c-0.22-0.09-0.47-0.14-0.74-0.14c-0.19,0-0.37,0.04-0.56,0.11c-0.19,0.07-0.35,0.18-0.47,0.32l-0.02-0.02l0.29-1.42h2.22V10.4h-2.9l-0.6,3.06h0.91c0.07-0.2,0.2-0.33,0.36-0.4c0.17-0.07,0.35-0.11,0.54-0.11c0.18,0,0.34,0.03,0.48,0.1c0.14,0.06,0.25,0.15,0.33,0.26c0.09,0.11,0.15,0.23,0.19,0.37c0.04,0.14,0.06,0.29,0.06,0.44c0,0.17-0.02,0.33-0.05,0.48C12.95,14.75,12.89,14.88,12.81,14.99z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-search{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M22.21,20.79l-5.69-5.69c2.37-3.07,2.16-7.5-0.66-10.31c-1.48-1.48-3.44-2.29-5.53-2.29S6.27,3.31,4.79,4.79C3.31,6.27,2.5,8.24,2.5,10.33s0.81,4.06,2.29,5.53c1.53,1.53,3.53,2.29,5.53,2.29c1.69,0,3.37-0.55,4.78-1.63l5.69,5.69c0.2,0.2,0.45,0.29,0.71,0.29s0.51-0.1,0.71-0.29C22.6,21.82,22.6,21.18,22.21,20.79z M6.21,14.45c-1.1-1.1-1.71-2.56-1.71-4.12s0.61-3.02,1.71-4.12S8.77,4.5,10.33,4.5s3.02,0.61,4.12,1.71c2.27,2.27,2.27,5.97,0,8.24C12.18,16.72,8.48,16.72,6.21,14.45z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-menu{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><path class="st0" d="M3,5c0-0.55,0.45-1,1-1h16c0.55,0,1,0.45,1,1s-0.45,1-1,1H4C3.45,6,3,5.55,3,5z M3,12c0-0.55,0.45-1,1-1h16c0.55,0,1,0.45,1,1s-0.45,1-1,1H4C3.45,13,3,12.55,3,12z M4,18c-0.55,0-1,0.45-1,1s0.45,1,1,1h16c0.55,0,1-0.45,1-1s-0.45-1-1-1H4z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-map{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21.3 16" style="enable-background:new 0 0 21.3 16;" xml:space="preserve"><path d="M14,16c0.1,0,0.1,0,0.2,0l6.6-2.3c0.1,0,0.3-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4V0.7c0-0.2-0.1-0.4-0.3-0.6C20.8,0,20.6,0,20.4,0L14,2.2L7.6,0C7.4,0,7.3,0,7.1,0L0.5,2.3c-0.1,0-0.3,0.1-0.3,0.2C0,2.7,0,2.8,0,3l0,12.3c0,0.2,0.1,0.4,0.3,0.6C0.5,16,0.7,16,0.9,16l6.4-2.2l6.4,2.2C13.8,16,13.9,16,14,16z M14.7,14.3V3.4l5.2-1.8v10.9L14.7,14.3z M13.3,14.3l-5.2-1.8V1.6l5.2,1.8V14.3z M1.4,14.3l0-10.9l5.2-1.8v10.9L1.4,14.3L1.4,14.3z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-keypad{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M18,15.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5s2.5-1.12,2.5-2.5S19.38,15.5,18,15.5z M18,19.5c-0.82,0-1.5-0.68-1.5-1.5s0.68-1.5,1.5-1.5s1.5,0.68,1.5,1.5S18.82,19.5,18,19.5z M18,9.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5s2.5-1.12,2.5-2.5S19.38,9.5,18,9.5z M18,13.5c-0.82,0-1.5-0.68-1.5-1.5s0.68-1.5,1.5-1.5s1.5,0.68,1.5,1.5S18.82,13.5,18,13.5z M18,8.5c1.38,0,2.5-1.12,2.5-2.5S19.38,3.5,18,3.5S15.5,4.62,15.5,6S16.62,8.5,18,8.5z M18,4.5c0.82,0,1.5,0.68,1.5,1.5S18.82,7.5,18,7.5S16.5,6.82,16.5,6S17.18,4.5,18,4.5z M12,3.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5s2.5-1.12,2.5-2.5S13.38,3.5,12,3.5z M12,7.5c-0.82,0-1.5-0.68-1.5-1.5s0.68-1.5,1.5-1.5s1.5,0.68,1.5,1.5S12.82,7.5,12,7.5z M6,3.5C4.62,3.5,3.5,4.62,3.5,6S4.62,8.5,6,8.5S8.5,7.38,8.5,6S7.38,3.5,6,3.5z M6,7.5C5.18,7.5,4.5,6.82,4.5,6S5.18,4.5,6,4.5S7.5,5.18,7.5,6S6.82,7.5,6,7.5z M12,9.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5s2.5-1.12,2.5-2.5S13.38,9.5,12,9.5z M12,13.5c-0.82,0-1.5-0.68-1.5-1.5s0.68-1.5,1.5-1.5s1.5,0.68,1.5,1.5S12.82,13.5,12,13.5z M12,15.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5s2.5-1.12,2.5-2.5S13.38,15.5,12,15.5z M12,19.5c-0.82,0-1.5-0.68-1.5-1.5s0.68-1.5,1.5-1.5s1.5,0.68,1.5,1.5S12.82,19.5,12,19.5z M6,15.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5s2.5-1.12,2.5-2.5S7.38,15.5,6,15.5z M6,19.5c-0.82,0-1.5-0.68-1.5-1.5s0.68-1.5,1.5-1.5s1.5,0.68,1.5,1.5S6.82,19.5,6,19.5z M6,9.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5s2.5-1.12,2.5-2.5S7.38,9.5,6,9.5z M6,13.5c-0.82,0-1.5-0.68-1.5-1.5s0.68-1.5,1.5-1.5s1.5,0.68,1.5,1.5S6.82,13.5,6,13.5z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-close{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M13.4,12l6.8-6.8c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.2,3.8c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l6.8,6.8l-6.8,6.8c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l6.8-6.8l6.8,6.8c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L13.4,12z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-chevron-up{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M18,15.5c-0.12,0-0.24-0.04-0.34-0.13L11.5,9.68l-6.16,5.69c-0.2,0.19-0.52,0.18-0.71-0.03c-0.19-0.2-0.17-0.52,0.03-0.71l6.5-6c0.19-0.18,0.49-0.18,0.68,0l6.5,6c0.2,0.19,0.22,0.5,0.03,0.71C18.27,15.45,18.13,15.5,18,15.5z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-chevron-down{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M11.5,15.5c-0.12,0-0.24-0.04-0.34-0.13l-6.5-6c-0.2-0.19-0.22-0.5-0.03-0.71c0.19-0.2,0.5-0.22,0.71-0.03l6.16,5.69l6.16-5.69c0.2-0.19,0.52-0.17,0.71,0.03c0.19,0.2,0.17,0.52-0.03,0.71l-6.5,6C11.74,15.46,11.62,15.5,11.5,15.5z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;
}

.icon-chevron-left{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M14.5,19c-0.13,0-0.27-0.05-0.37-0.16l-6-6.5c-0.18-0.19-0.18-0.49,0-0.68l6-6.5c0.19-0.2,0.5-0.22,0.71-0.03c0.2,0.19,0.22,0.5,0.03,0.71L9.18,12l5.69,6.16c0.19,0.2,0.17,0.52-0.03,0.71C14.74,18.96,14.62,19,14.5,19z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}

.icon-chevron-right{
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M9.5,19c-0.12,0-0.24-0.04-0.34-0.13c-0.2-0.19-0.22-0.5-0.03-0.71L14.82,12L9.13,5.84c-0.19-0.2-0.17-0.52,0.03-0.71c0.2-0.19,0.52-0.17,0.71,0.03l6,6.5c0.18,0.19,0.18,0.49,0,0.68l-6,6.5C9.77,18.95,9.63,19,9.5,19z"/></svg>');

  background-repeat: no-repeat;
  background-position: center;

  filter: invert(100%);
}