.text-component {
  display: flex;
  flex-direction: column;
  padding-top: rem(36);
  padding-bottom: rem(36);
  .tag {
    margin-bottom: rem(20);
    order: -1;
  }
  .heading, .heading-about {
    margin-bottom: rem(14);
  }
  .heading, .heading-about, p {
    order: -1;
  }

  .heading-about {
    color: $purple;
  }

  p {
    display: inline-table;
    // Highlight Animation
    .highlighted-text {
      display: inline;
      background: linear-gradient(to right, rgba(255 255 255 / 0%) 50%, $bronze 50%);
      background-position: 0 0;
      background-size: 200%;
    }    
  }
  .text-link{
    color: $green;
  }

  /* Background Options */

  /* 1. Blue tag, white background, black text */
  &.opt1 {
    .tag {
      background-image: $steel-gradient;
    }
  }

  /* 2. Brown tag, black background, white text */
  &.opt2 {
    color: $white;    
    background-color: $grey-8;
    .heading {
      color: $white;
    }
  }
}

b, strong {
  font-family: $font-body-bold;
}