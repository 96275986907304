
// Headings

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  color: $black;
  letter-spacing: normal;
}

h1,
h2 {
  font-weight: 700;
  font-family: HelveticaNeueBold;
}

h1 {
  font-size: rem(36);
  line-height: 1.22;  
}

h2 {
  font-size: rem(28);
  line-height: 1.36;
}

h3 {
  font-weight: 300;
  font-size: rem(28);
  font-family: HelveticaNeueLight;
  line-height: 1.29;
}

h4 {
  font-weight: normal;
  font-size: rem(22);
  font-family: HelveticaNeue;
  line-height: 1.36;
}

h5 {
  font-weight: 300;
  font-size: rem(22);
  font-family: HelveticaNeueLight;
  line-height: 1.36;
}

h6 {
  font-weight: 500;
  font-size: rem(16);
  font-family: HelveticaNeueMedium;
  line-height: 1.5;
}

// General Styling
body,
button,
input,
select,
optgroup,
textarea {
  color: $grey-4;
  font-size: rem(16);
  font-family: $font-body;
  line-height: 1.5;
}

// Primary Menu
.menu-item {
  font-weight: normal;
  font-size: rem(22);
  font-family: HelveticaNeue;
  line-height: 1.36;
}

// Secondary Menu
.language-option{
  font-weight: bold;
  font-size: rem(16);
  letter-spacing: rem(1);
  text-transform: uppercase;
}

.info-telus > * {
  font-size: rem(14);
  line-height: 1.57;
  letter-spacing: normal;
}

// Audio Player
.audio-player {
  color: $white;
  .audio-title {
    font-weight: 500;
    font-size: rem(16);
    font-family: HelveticaNeueMedium;
  }
  .audio-time {
    font-size: rem(13);
  }
}

// Search
.search-header{
  font-size: rem(22);
  font-family: HelveticaNeueMedium;
  line-height: 1.36;
  letter-spacing: normal;
}

.search-input {
  font-size: rem(14);
  font-family: HelveticaNeue;
  line-height: 1.57;
  letter-spacing: normal;
}

.text-link {
  font-weight: normal;
  font-size: rem(14);
  font-family: $font-body;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
}

// List
.list-title {
  font-weight: bold;
  font-size: rem(14);
  font-family: HelveticaNeueBold;
  line-height: 1.57;
  letter-spacing: normal;
}

.list-detail {
  font-weight: normal;
  font-size: rem(14);
  font-family: HelveticaNeue;
  line-height: 1.57;
  letter-spacing: normal;
}

// Gallery
.slider-pagination {
  margin: 0;
  font-weight: bold;
  font-size: rem(14);
  font-family: HelveticaNeueBold;
}

// Quote
.quote {
  font-weight: 500;
  font-size: rem(24);
  font-family: HelveticaNeueMedium;
  font-style: italic;
  line-height: 1.33;
}