.map-page{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-top: rem(72);
  overflow: hidden;
  background-color: $grey-9;

  .map-container{
    width: 100%;
    height: rem(448);
    .map-image{
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .detail-header{
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(56);

    padding: rem(12) rem(24);
    background-color: $grey-8;
  }

  .detail-body{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: rem(10);
    width: 100%;
    margin: 0;
    padding: rem(10) rem(24);
    overflow: scroll;
    overflow-x: hidden;
    background-color: $steel-4;

    .row{
      .art-id{
        color: $white;
      }

      .art-info{
        color: $grey-2;

        font-weight: normal;
        font-size: rem(14);
        font-family: $font-body;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        opacity: 0.9;

        &.selected{
          color: $white;
          font-weight: 700;
          font-family: $font-body-bold;
          opacity: 1;
        }
      }
    }
  }

  .bottom-line {
    width: 100%;
    height: rem(5);
    background: linear-gradient(180deg, #c7dae7 0%, #597792 100%);
  }
}