// General Styling
p, ul {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: rem(10);
}

ul {  
  list-style: none;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

button{
  padding: 0;
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  outline: inherit;
  cursor: pointer;
}

a{
  &:link, &:visited, &:hover, &:active {
    text-decoration: none;
  }
}

#root {
  > .main-container {
    width: 100%;
  }
}

.container {
  padding: 0 rem(24);
}

.visually-hidden {
  position: absolute;
  width: rem(1);
  height: rem(1);
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(rem(1), rem(1), rem(1), rem(1));
  clip-path: inset(0 0 99.9% 99.9%);
}
